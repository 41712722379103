import { shadows } from "@sctex/uikit/src/theme/base";
import { darkColors, lightColors } from "@sctex/uikit/src/theme/colors";
export var light = {
    background: darkColors.backgroundAlt,
    text: darkColors.text,
    boxShadow: shadows.tooltip
};
export var dark = {
    background: lightColors.backgroundAlt,
    text: lightColors.text,
    boxShadow: shadows.tooltip
};
