function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { dark as darkAlert } from "@sctex/uikit/src/components/Alert/theme";
import { dark as darkCard } from "@sctex/uikit/src/components/Card/theme";
import { dark as darkPancakeToggle } from "@sctex/uikit/src/components/PancakeToggle/theme";
import { dark as darkRadio } from "@sctex/uikit/src/components/Radio/theme";
import { dark as darkToggle } from "@sctex/uikit/src/components/Toggle/theme";
import { dark as darkNav } from "@sctex/uikit/src/widgets/Menu/theme";
import { dark as darkModal } from "@sctex/uikit/src/widgets/Modal/theme";
import { dark as darkTooltip } from "@sctex/uikit/src/components/Tooltip/theme";
import base from "@sctex/uikit/src/theme/base";
import { darkColors } from "@sctex/uikit/src/theme/colors";
var darkTheme = _objectSpread({}, base, {
    isDark: true,
    alert: darkAlert,
    colors: darkColors,
    card: darkCard,
    toggle: darkToggle,
    nav: darkNav,
    modal: darkModal,
    pancakeToggle: darkPancakeToggle,
    radio: darkRadio,
    tooltip: darkTooltip
});
export default darkTheme;
