import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
   {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'SCTEX',
    lpAddresses: {
      25437: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      1455: '0x9367e06228cbc0E8DC09CB2B821D5c532e7309a5',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wctex,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'SCTEX-CTEX LP',
    lpAddresses: {
      25437: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      1455: '0xbC8B9BdFC810D3760f5B6D69a1A5604D9dAaAf5f',
    },
    token: serializedTokens.sctex,
    quoteToken: serializedTokens.wctex,
  },
  {
    pid: 3,
    // v1pid: 2,
    lpSymbol: 'BUSD-CTEX LP',
    lpAddresses: {
      25437: '0xc8EdeC730CC6aBb57c213c840633071164357357',
      1455: '0xE435dfCfF3eb7dEB3b29db0f93bA09d0F2082D40',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wctex,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  {
    pid: 4,
    // v1pid: 3,
    lpSymbol: 'USDT-CTEX LP',
    lpAddresses: {
      25437: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
      1455: '0xb640E5803366cC40f2175daE2b0bE6dF726e0FdC',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wctex,
  },
  // {
  //   pid: 5,
  //   lpSymbol: 'ETH-CTEX LP',
  //   lpAddresses: {
  //     25437: '',
  //     1455: '0x8c213E3170bC941b56eD76eC2FAE884C79074bCE',
  //   },
  //   token: serializedTokens.eth,
  //   quoteToken: serializedTokens.wctex,
  // },


  
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
