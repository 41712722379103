function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        " {\n    ",
        ";\n  }\n  flex-grow: 1;\n  background-color: #FFFFFF;\n  box-shadow: inset 0px -2px 0px -8px rgba(133, 133, 133, 0.1);\n  overflow-x: scroll;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { Flex } from "@sctex/uikit/src/components/Box";
var StyledSubMenuItems = styled(Flex).withConfig({
    componentId: "sc-7289afc7-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
}, function(param) {
    var $isMobileOnly = param.$isMobileOnly;
    return $isMobileOnly ? "display:none" : "";
});
export default StyledSubMenuItems;
