function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  display: flex;\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "@sctex/uikit/src/components/Box/Box";
var Flex = styled(Box).withConfig({
    componentId: "sc-2f2ae42d-0"
})(_templateObject(), flexbox);
export default Flex;
