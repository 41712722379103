function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  background: #000000;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  list-style: none;\n  margin-bottom: 40px;\n\n  ",
        " {\n    margin-bottom: 0px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n  font-size: 16px;\n  margin-bottom: 8px;\n  text-transform: capitalize;\n\n  &:first-child {\n    color: ",
        ";\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n  margin-bottom: 24px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n  border-color: ",
        ";\n  border-top-width: 1px;\n  border-bottom-width: 1px;\n  border-style: solid;\n  padding: 24px 0;\n  margin-bottom: 24px;\n\n  ",
        " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n  border-bottom: 1px solid ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n  color: #FBD520;\n  font-weight: 700;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n  color: #ffffff;\n  line-height: 26px;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { darkColors } from "@sctex/uikit/src/theme/colors";
import { Box, Flex } from "@sctex/uikit/src/components/Box";
import SocialLinks from "@sctex/uikit/src/components/Footer/Components/SocialLinks";
export var StyledFooter = styled(Flex).withConfig({
    componentId: "sc-5d9f0717-0"
})(_templateObject());
export var StyledList = styled.ul.withConfig({
    componentId: "sc-5d9f0717-1"
})(_templateObject1(), function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
});
export var StyledListItem = styled.li.withConfig({
    componentId: "sc-5d9f0717-2"
})(_templateObject2(), darkColors.secondary);
export var StyledIconMobileContainer = styled(Box).withConfig({
    componentId: "sc-5d9f0717-3"
})(_templateObject3());
export var StyledToolsContainer = styled(Flex).withConfig({
    componentId: "sc-5d9f0717-4"
})(_templateObject4(), darkColors.cardBorder, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks).withConfig({
    componentId: "sc-5d9f0717-5"
})(_templateObject5(), darkColors.cardBorder);
export var StyledText = styled.span.withConfig({
    componentId: "sc-5d9f0717-6"
})(_templateObject6(), darkColors.text);
export var StyledTextDisclaimer = styled.span.withConfig({
    componentId: "sc-5d9f0717-7"
})(_templateObject7());
export var StyledTextDisclaimerContent = styled.span.withConfig({
    componentId: "sc-5d9f0717-8"
})(_templateObject8());
